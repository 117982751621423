<template>
  <v-container>
      <v-row class="trade fill-height" tag="section">
        <v-col class="user" cols="5"  >
          <div class="filter-header">
            Filter Header
          </div>
          <div class="userinventory " height="800px">

             sss
          </div>
        </v-col>
        <v-col class="filter" cols="2">
          <div class="filters">
ddd
          </div>
        </v-col>
        <v-col class="bot" cols="5">
          <div class="filter-header">
            Filter Header
          </div>
          <div class="botInventory">
ddd
          </div>
        </v-col>
      </v-row>

  </v-container>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
};
</script>

<style lang="scss">

.filter-header {
  background-color: #1B211B;
  height: 50px;
}
.userinventory {
  background-color: white;
  height: 80vh;
}
.filters {
  background-color: white;
  height: 50vh;
}
.botInventory {
  background-color: white;
  height: 80vh;
}

</style>
